<template>
  <div class="flex justify-center items-center min-h-screen bg-gradient-to-r from-amber-400 via-yellow-300 to-gray-200">
    <div class="p-8 bg-white rounded-lg shadow-xl max-w-sm w-full">
      <div class="text-center mb-6">
        <img src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png"
          alt="Company Logo" class="mx-auto h-24 w-auto">
        <h2 class="text-2xl font-bold text-gray-800">Acesso Restrito</h2>
      </div>
      <div class="mb-4">
        <label for="username" class="block text-sm font-medium text-gray-700 mb-2">Usuário</label>
        <input id="username" v-model="username" type="text" placeholder="Digite seu Usuário" required
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent" />
      </div>
      <div class="mb-6">
        <label for="password" class="block text-sm font-medium text-gray-700 mb-2">Senha</label>
        <div class="relative">
          <input id="password" v-model="password" :type="passwordVisible ? 'text' : 'password'"
            placeholder="Digite sua Senha" required
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent" />
          <span class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
            <button type="button" @click="togglePasswordVisibility"
              class="text-gray-500 focus:outline-none focus:text-gray-700">
              <span v-show="passwordVisible">Ocultar</span>
              <span v-show="!passwordVisible">Mostrar</span>
            </button>
          </span>
        </div>
      </div>
      <button @click="login" :disabled="isButtonDisabled"
        class="w-full bg-gradient-to-r from-yellow-500 to-gray-300 hover:bg-gradient-to-r text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 transition duration-150 ease-in-out">Login</button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import authService from '@/service/authService';
import { useRouter } from 'vue-router';

const username = ref('');
const password = ref('');
const passwordVisible = ref(false);
const router = useRouter();

const isButtonDisabled = computed(() => {
  return username.value.trim() === '' || password.value.trim() === '';
});

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

const login = async () => {
  try {
    const response = await authService.login({ username: username.value, password: password.value });
    if (response.success) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      alert('Login successful');
      router.push('/dashboard');
    } else {
      alert('Login failed: ' + response.message);
    }
  } catch (error) {
    console.error('Login failed', error);
    alert('Login failed');
  }
};
</script>
